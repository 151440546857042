WebFont.load({
	google: {
		families: ['Source+Sans+Pro:300,600:latin-ext']
	}
});

jQuery(document).ready(function($) {

	//initPhotoSwipeFromDOM('.my-gallery');


});
